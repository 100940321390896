import * as S from './styles';
import React from 'react';
import { Tabs } from '@veneer/core';
import OverrideComponent from '../OverrideComponent';
import ActionsComponent from '../ActionsComponent';
const ModalComponent = ({ openModal, setOpenModal }) => {
  const tabs = [
    {
      id: 1,
      label: 'Overrides',
      content: <OverrideComponent />
    },
    {
      id: 2,
      label: 'Actions',
      content: <ActionsComponent />
    }
  ];

  const [selectedTabIdDefault, setSelectedTabIdDefault] = React.useState(
    tabs[0].id
  );

  return (
    <S.ModalTool
      expanded={true}
      maxWidth={'1199px'}
      align="start"
      show={openModal}
      onClose={() => setOpenModal(false)}
      title="JShell Tools"
    >
      <Tabs
        controlId="default"
        mode="extended"
        onChangeTab={(id) => setSelectedTabIdDefault(id)}
        selectedTabId={selectedTabIdDefault}
        tabs={tabs}
        type="online"
      />
    </S.ModalTool>
  );
};

export default ModalComponent;
