import { Button, ButtonGroup } from '@veneer/core';
import * as S from './styles';
import React from 'react';
import { MANIFEST_STORAGE_KEY } from '../../constants';
import JSONEditorComponent from '../JSONEditorComponent';

export type ManifestOverrideComponentProps = {
  manifest: string;
  setManifest: React.Dispatch<React.SetStateAction<string>>;
  unsavedManifest: string;
  setUnsavedManifest: React.Dispatch<React.SetStateAction<string>>;
};

const ManifestOverrideComponent = ({
  manifest,
  setManifest,
  unsavedManifest,
  setUnsavedManifest
}: ManifestOverrideComponentProps) => {
  const [manifestError, setManifestError] = React.useState<string>('');

  const saveManifest = (): boolean => {
    try {
      JSON.parse(manifest);
    } catch (error) {
      setUnsavedManifest('');
      setManifestError('This Manifest is not a valid JSON.');
      return false;
    }
    setManifestError('');
    !!manifest && localStorage.setItem(MANIFEST_STORAGE_KEY, manifest);
    return true;
  };

  const handleLoadManifest = () => {
    const defaultManifest = window.Shell?.manifest;
    !!defaultManifest && setManifest(JSON.stringify(defaultManifest, null, 2));
    setUnsavedManifest('');
    setManifestError('');
  };

  const clearManifest = () => {
    setManifest('');
    localStorage.removeItem(MANIFEST_STORAGE_KEY);
    setUnsavedManifest('');
    setManifestError('');
  };

  const handleSave = () => {
    saveManifest() && window.location.reload();
  };

  const onChange = (value: string) => {
    setManifest(value);
    setUnsavedManifest(() =>
      value !== JSON.stringify(window.Shell?.manifest, null, 2)
        ? 'Manifest is not saved.'
        : ''
    );
    setManifestError('');
  };

  return (
    <div>
      <S.Title>Manifest Override</S.Title>
      <JSONEditorComponent
        data-testid="jshell-tool-manifest-editor"
        isNotSaved={!!unsavedManifest}
        name="shell-tools-manifest-override-editor"
        value={manifest}
        onChange={(value) => onChange(value)}
        annotations={
          manifestError
            ? [{ row: 0, column: 0, text: manifestError, type: 'error' }]
            : []
        }
      />
      <a
        href="https://pages.github.azc.ext.hp.com/jarvis-shell/shell-docs/docs/guides-and-tutorials/development/loading-your-mfe#injecting-your-local-mfe-inside-a-portal"
        target="_blank"
        rel="noreferrer"
      >
        Click here for documentation.
      </a>
      <ButtonGroup>
        <Button
          appearance="ghost"
          onClick={clearManifest}
        >
          Clear Override
        </Button>
        <Button
          appearance="secondary"
          onClick={handleLoadManifest}
        >
          Load Manifest
        </Button>
        <Button onClick={handleSave}>Save and Reload</Button>
      </ButtonGroup>
    </div>
  );
};

export default ManifestOverrideComponent;
