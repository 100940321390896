import { Button, ButtonGroup } from '@veneer/core';
import * as S from './styles';
import React from 'react';
import { IMPORTMAP_STORAGE_KEY } from '../../constants';
import JSONEditorComponent from '../JSONEditorComponent';

export type ImportmapOverrideComponentProps = {
  importmap: string;
  setImportmap: React.Dispatch<React.SetStateAction<string>>;
  unsavedImportmap: string;
  setUnsavedImportmap: React.Dispatch<React.SetStateAction<string>>;
};

const ImportmapOverrideComponent = ({
  importmap,
  setImportmap,
  unsavedImportmap,
  setUnsavedImportmap
}: ImportmapOverrideComponentProps) => {
  const [importmapError, setImportmapError] = React.useState<string>('');

  const saveImportmap = (): boolean => {
    try {
      JSON.parse(importmap);
    } catch (error) {
      setUnsavedImportmap('');
      setImportmapError('This Importmap is not a valid JSON.');
      return false;
    }
    setImportmapError('');
    !!importmap && localStorage.setItem(IMPORTMAP_STORAGE_KEY, importmap);
    return true;
  };

  const handleLoadImportmap = () => {
    const defaultImportmap = window.Shell?.importmap;
    !!defaultImportmap &&
      setImportmap(JSON.stringify(defaultImportmap, null, 2));
    setUnsavedImportmap('');
    setImportmapError('');
  };

  const clearImportmap = () => {
    setImportmap('');
    localStorage.removeItem(IMPORTMAP_STORAGE_KEY);
    setUnsavedImportmap('');
    setImportmapError('');
  };

  const handleSave = () => {
    saveImportmap() && window.location.reload();
  };

  const onChange = (value: string) => {
    setImportmap(value);
    setUnsavedImportmap(() =>
      value !== JSON.stringify(window.Shell?.importmap, null, 2)
        ? 'Importmap is not saved.'
        : ''
    );
    setImportmapError('');
  };

  return (
    <div>
      <S.Title>Importmap Override</S.Title>
      <JSONEditorComponent
        isNotSaved={!!unsavedImportmap}
        name="shell-tools-importmap-override-editor"
        value={importmap}
        onChange={(value) => onChange(value)}
        annotations={
          importmapError
            ? [{ row: 0, column: 0, text: importmapError, type: 'error' }]
            : []
        }
      />
      <a
        href="https://pages.github.azc.ext.hp.com/jarvis-shell/shell-docs/docs/guides-and-tutorials/development/loading-your-mfe#injecting-your-local-mfe-inside-a-portal"
        target="_blank"
        rel="noreferrer"
      >
        Click here for documentation.
      </a>
      <ButtonGroup>
        <Button
          appearance="ghost"
          onClick={clearImportmap}
        >
          Clear Override
        </Button>
        <Button
          appearance="secondary"
          onClick={handleLoadImportmap}
        >
          Load Importmap
        </Button>
        <Button onClick={handleSave}>Save and Reload</Button>
      </ButtonGroup>
    </div>
  );
};

export default ImportmapOverrideComponent;
